import $ from 'jquery';
import 'select2';

const selectComponent = (node) => {
  const $select = $(node);
  const placeholder = $select.attr('placeholder');
  const selectBox = $select.closest('.select');
  const hasBlueTheme = selectBox.get(0).classList.contains('select--blue');
  const theme = hasBlueTheme ? 'select--blue' : '';

  $select.select2({
    width: '100%',
    placeholder,
    allowClear: true,
    dropdownCssClass: theme,
  });

  $select.on('select2:open', () => {
    selectBox.addClass('is-focus');
  });

  $select.on('select2:close', () => {
    selectBox.removeClass('is-focus');
  });

  $select.on('select2:select', () => {
    selectBox.addClass('is-filled');
  });

  return $select;
};

export default selectComponent;
