import $ from 'jquery';
import Cookies from '@components/cookies/scripts.js';
import modal from '@components/modal/scripts';
import registerFormValidator from '@components/formValidator/init.js';
import dragScrollInit from '@components/drag-scroll/scripts';
import inputFile from '@components/input-file/scripts';
import select from '@components/select/scripts';
import range from '@components/range/scripts';
import tabsInit from '@components/tabs/scripts';

import rangeComponent from '@components/range/range.js';
import selectComponent from '@components/select/select.js';

const initGlobals = (namespace) => {
  global[namespace] = {
    $,
    cookies: new Cookies(),
    registerFormValidator,
    inputFile,
    modal,
    dragScrollInit,
    select,
    range,
    tabsInit,
    rangeComponent,
    selectComponent,
  };
};

export default initGlobals;
