import IMask from 'imask';

const regexpPhone = new RegExp('(7|8)\\s[\(][0-9]{3}[\)]\\s[0-9]{3}[\-][0-9]{2}[\-][0-9]{2}');
const regexpMail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

const phoneElement = document.querySelectorAll('.jsPhoneMask');

const mailElement = document.querySelectorAll('.jsMailMask');

const phoneMaskSettings = {
  mask: [
    {
      mask: '8 (000) 000-00-00',
      startsWith: '8',
      lazy: true,
    },
    {
      mask: '+{7} (000) 000-00-00',
      startsWith: '7',
      lazy: true,
    },
    {
      mask: '+{7} (000) 000-00-00',
      startsWith: '',
      lazy: true,
    },
    {
      mask: '+{7} (000) 000-00-00',
      startsWith: '9',
      lazy: true,
    },
  ],
  dispatch(appended, dynamicMasked) {
    const number = (dynamicMasked.value + appended).replace(/\D/g, '');

    return dynamicMasked.compiledMasks.find((m) => number.indexOf(m.startsWith) === 0) || this.dynamicMasked.compiledMasks[this.dynamicMasked.compiledMasks.length - 1];
  },
};

if (phoneElement.length > 0) {
  for (let i = 0; i < phoneElement.length; i++) {
    const mask = IMask(phoneElement[i], phoneMaskSettings);
  }
}

const jsInput = document.querySelectorAll('.jsInput');

for (let index = 0; index < jsInput.length; index++) {
  const input = jsInput[index];

  input.onblur = function () {
    window.inputStatusRemove(input, 'focus')

    if (input.value != '') {
      window.inputStatusAdd(input, 'filled')
    }
  };

  input.onfocus = function () {
    window.inputStatusAdd(input, 'focus')
    window.inputStatusRemove(input, 'error')
    window.inputStatusRemove(input, 'filled')
  };

  document.addEventListener('DOMContentLoaded', () => {
    if (input.value != '') {
      window.inputStatusAdd(input, 'filled')
    }
  })
}


const jsInputFile = document.querySelectorAll('.jsInputFile');

for (let index = 0; index < jsInputFile.length; index++) {
  const input = jsInputFile[index];
  const field = input.querySelector('.jsInputFileField');
  const name = input.querySelector('.jsInputFileName');
  const del = input.querySelector('.jsInputFileDelete');

  del.addEventListener('click', function (e) {
    e.preventDefault()
    field.value == '';
    input.classList.remove('is-upload');
    name.innerHTML = '';
  })

  field.addEventListener('change', function () {
    if (field.files.length != 0) {
      input.classList.add('is-upload');
      name.innerHTML = this.files[0].name;
    } else {
      input.classList.remove('is-upload');
      name.innerHTML = '';
    }
  });

  document.addEventListener('DOMContentLoaded', () => {
    if (field.files.length != 0) {
      input.classList.add('is-upload');
      name.innerHTML = this.files[0].name;
    }
  })
}

// let mailInputs = document.querySelectorAll('.jsMailMask');

// for (let index = 0; index < mailInputs.length; index++){
//     let input = mailInputs[index];
//     input.addEventListener('input', function(){
//         let result = window.InputCheckMask(input, 'mail');
//         console.log(result);
//     })
// }





// Input status
// Варианты:  'focus' ; 'error' ; 'fill' ; 'ok' ;
// window.inputStatusAdd(input, 'error')
// window.inputStatusRemove(input, 'error')

window.inputStatusAdd = function (input, status) {
  if (input.classList.contains('.input')) {
    input.classList.add('is-' + status);
  } else if (input.closest('.input')) {
    input.closest('.input').classList.add('is-' + status);
  } else if (input.closest('.search')) {
    input.closest('.search').classList.add('is-' + status);
  }
}

window.inputStatusRemove = function (input, status) {
  if (input.classList.contains('.input')) {
    input.classList.remove('is-' + status);
  } else if (input.closest('.input')) {
    input.closest('.input').classList.remove('is-' + status);
  } else if (input.closest('.search')) {
    input.closest('.search').classList.remove('is-' + status);
  }
}

// Input masks
// Варианты:  'mail' ; 'phone'
// window.InputCheckMask(input, 'mail')

window.InputCheckMask = function (input, type) {
  if (type === 'mail') {
    // console.log('mail');
    return regexpMail.test(input.value);
  }
  if (type === 'phone') {
    // console.log('phone');
    return regexpPhone.test(input.value);
  }
}

window.runMask = function () {
  const phoneElement = document.querySelectorAll('.jsPhoneMask');
  const phoneMaskSettings = {
    mask: [
      {
        mask: '8 (000) 000-00-00',
        startsWith: '8',
        lazy: true,
      },
      {
        mask: '+{7} (000) 000-00-00',
        startsWith: '7',
        lazy: true,
      },
      {
        mask: '+{7} (000) 000-00-00',
        startsWith: '',
        lazy: true,
      },
      {
        mask: '+{7} (000) 000-00-00',
        startsWith: '9',
        lazy: true,
      },
    ],
    dispatch(appended, dynamicMasked) {
      const number = (dynamicMasked.value + appended).replace(/\D/g, '');

      return dynamicMasked.compiledMasks.find((m) => number.indexOf(m.startsWith) === 0) || this.dynamicMasked.compiledMasks[this.dynamicMasked.compiledMasks.length - 1];
    },
  };
  if (phoneElement.length > 0) {
    for (let i = 0; i < phoneElement.length; i++) {
      const mask = IMask(phoneElement[i], phoneMaskSettings);
    }
  }
}

// window.runMask() перезапуск маски

const inputSwapComponent = (inputNode) => {
  let parent = inputNode.closest('.input');
  let input = parent.querySelector('input');

  function toggleType() {
    if (input.getAttribute('type') == 'password') {
      input.setAttribute('type', 'text')
    } else if (input.getAttribute('type') == 'text') {
      input.setAttribute('type', 'password')
    }
  }

  inputNode.addEventListener('click', (e) => {
    inputNode.classList.toggle('is-active');
    toggleType();
    input.focus();
  });
};

const initInputSwapComponent = (inputSelector) => {
  const jsInputSwapNodes = Array.from(document.querySelectorAll(inputSelector));

  jsInputSwapNodes.forEach(inputSwapComponent);
}

window.initInputSwapComponent = initInputSwapComponent;

// window.initInputSwapComponent() перезапуск инпутов со свапом

initInputSwapComponent('.jsInputSwap');
