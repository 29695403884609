document.addEventListener('DOMContentLoaded', function () {
  const windowHeight = window.innerHeight;

  if(document.querySelector('.header')){
    window.header = {
      main: document.querySelector('.header'),
      menu: document.querySelector('.header__dropdown'),
      burgerTrigger: document.querySelector('.header__burger'),
      burgerClose: document.querySelector('.jsBurgerClose'),
      rotateIndex: 0,
      navs: document.querySelectorAll('.header-nav__group'),

      rotate: function (index){
        index += 1;
        window.header.burgerTrigger.style.transform = 'rotate('+ (index * 360) +'deg)';
        return index
      },

      open: function(){
        window.header.main.classList.add('is-open')
        window.header.menu.classList.add('is-open')
        window.header.burgerTrigger.classList.add('is-open')
        // document.querySelector('body').classList.add('_lock');
        window.header.rotateIndex = window.header.rotate(window.header.rotateIndex);
      },

      close: function (){
        window.header.main.classList.remove('is-open')
        window.header.menu.classList.remove('is-open')
        window.header.burgerTrigger.classList.remove('is-open')
        window.header.navs.forEach(nav => {
          const body = nav.querySelector('.header-nav__body')
          nav.classList.remove('is-open')
          body.style.maxHeight = '';
        })
        // document.querySelector('body').classList.remove('_lock');
        window.header.rotateIndex = window.header.rotate(window.header.rotateIndex);
      }
    };

    window.header.burgerTrigger.addEventListener('click', function(){
      if (window.header.main.classList.contains('is-open')) {
        window.header.close();
      } else{
        window.header.open();
      }
    })

    window.header.burgerClose.addEventListener('click', function(){
      if (window.header.main.classList.contains('is-open')) {
        window.header.close();
      }
    })

    document.addEventListener('click', e => {
      const target = e.target
      if (window.header.main.classList.contains('is-open')) {
        if (!target.closest('.header')) {
          window.header.close();
        }
      }
    })

    document.addEventListener('scroll', function(){
      if(window.pageYOffset >= (windowHeight * 0.25)){
        window.header.main.classList.add('is-fixed')
      } else{
        window.header.main.classList.remove('is-fixed')
      }
    })
  }
})
