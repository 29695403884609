document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('[data-nav="component"]')) {
		const nav = document.querySelector('[data-nav="component"]');
		const items = nav.querySelectorAll('[data-nav="item"]');
	
		const check = (current) => {
			items.forEach(item => {
				const body = item.querySelector('[data-nav="body"]');
				const content = item.querySelector('[data-nav="content"]');
	
				if(current != item) {
					item.classList.remove('is-open')
					body.style.maxHeight = '';
	
				} else if (item.classList.contains('is-open')) {
					body.style.maxHeight = content.offsetHeight + 'px';
				} else {
					body.style.maxHeight = '';
				}
			})
		}
		
		items.forEach(item => {
			const header = item.querySelector('[data-nav="trigger"]');
			
			header.onclick = () => {
				item.classList.toggle('is-open');
				check(item)
			}
		})
	
		if(document.querySelector('[data-nav="item"].is-open')){
			check(document.querySelector('[data-nav="item"].is-open'))
		}
	}
})
