import Swiper, {
 Navigation, Pagination, Autoplay, FreeMode, Mousewheel, EffectFade, Thumbs
} from 'swiper';

window.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('.home-slider .swiper-container')) {
    return false;
  } else {
    const swiper = new Swiper('.home-slider .swiper-container', {
      modules: [Autoplay, Navigation],

      slidesPerView: 1,
      spaceBetween: 10,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 5000,
      },
    });

    document.querySelector('.swiper-button-next').addEventListener('click', handleBtnClick);
    document.querySelector('.swiper-button-prev').addEventListener('click', handleBtnClick);
    function handleBtnClick() {
      swiper.autoplay.start();
      slideStartProgressBar();
    }
    function slideStartProgressBar() {
      const slideActive = document.querySelector('.swiper-slide-active');
      startProgressBar(slideActive);
    }
    function startProgressBar(button) {
      const progressBar = button.querySelector('.progress-bar');
      progressBar.style.transition = 'none';
      progressBar.style.width = '0';

      setTimeout(() => {
        progressBar.style.transition = 'width 5s linear';
        progressBar.style.width = '100%';
      }, 100);
    }

    const buttons = document.querySelectorAll('.slider-button');

    buttons.forEach((button, index) => {
      button.addEventListener('click', () => {
        swiper.slideTo(index + 1);

        buttons.forEach((btn) => {
          btn.classList.remove('active');
        });
        button.classList.add('active');

        startProgressBar(button);
        slideStartProgressBar()

        swiper.autoplay.start();
      });
    });

    swiper.on('slideChange', () => {

      const activeIndex = swiper.realIndex + 1;
      const totalSlides = swiper.slides.length - 2;
      const counterText = `${activeIndex} из ${totalSlides}`;

      const counterElement = document.querySelector('.counter');

      if (counterElement) {
        counterElement.textContent = counterText; // Обновляем текст каунтера
      }

      const allButtons = document.querySelectorAll('.slider-button');

      allButtons.forEach((button) => {
        button.classList.remove('active');
      });

      const activeSlide = document.querySelector(`.swiper-slide:nth-child(${swiper.realIndex + 2})`);
      const activeButton = document.querySelector(`.slider-button:nth-child(${swiper.realIndex + 1})`);

      activeButton.classList.add('active');

      startProgressBar(activeButton);
      startProgressBar(activeSlide);
    });

    const btnActive = document.querySelector('.slider-buttons .active')

    startProgressBar(btnActive);
    slideStartProgressBar();
  }
});


const jsSliderPromo = new Swiper('.jsSliderPromo .slider__inner', {
	modules: [Navigation, Pagination, Autoplay],

	pagination: {
		el: ".jsSliderPromo .swiper-pagination",
		clickable: true,
		bulletClass: "slider__pagination-item",
	},
    navigation: {
        nextEl: '.jsSliderPromo .swiper-button-next',
        prevEl: '.jsSliderPromo .swiper-button-prev',
    },

	loop: true,
	slidesPerView: 1,
    speed: 900,

	breakpoints: {
		0:{
			spaceBetween: 20,
		},

		834: {
			spaceBetween: 30,
		},

        992: {
			spaceBetween: 40,
		},

		1260: {
            spaceBetween: 60,
		},
	}
});

const jsSliderArticles = new Swiper('.jsSliderArticles .slider__inner', {
	modules: [Navigation, Pagination, Autoplay],
	loop: false,
	slidesPerView: 1,
	speed: 900,

	pagination: {
		el: ".jsSliderArticles .swiper-pagination",
		clickable: true,
		bulletClass: "slider__pagination-item",
	},
    navigation: {
        nextEl: '.jsSliderArticles .swiper-button-next',
        prevEl: '.jsSliderArticles .swiper-button-prev',
    },

	breakpoints: {
		0:{
			spaceBetween: 20,
		},

		834: {
			spaceBetween: 30,
		},

        992: {
			spaceBetween: 40,
		},

		1260: {
            spaceBetween: 60,
		},
	}
});

const jsSliderArticlesList = new Swiper('.jsSliderArticlesList .slider__inner', {
	modules: [Autoplay],
	loop: false,
	slidesPerView: 4,
	speed: 750,

	autoplay: {
		delay: 8000,
	},

	breakpoints: {
		0:{
			spaceBetween: 5,
			slidesPerView: 'auto',
            autoHeight: true,
		},
		834: {
			slidesPerView: 2,
			spaceBetween: 20,
            autoHeight: false,
		},

        992: {
			slidesPerView: 3,
			spaceBetween: 24,
		},

		1440: {
            slidesPerView: 4,
			spaceBetween: 25,
		},
	}
});

const jsSliderListArticle = new Swiper('.jsSliderListArticle .slider__inner', {
	modules: [Pagination, Autoplay, EffectFade],
	loop: true,
	slidesPerView: 1,
    speed: 750,

    autoplay: {
		delay: 6000,
	},

	pagination: {
		el: ".jsSliderListArticle .swiper-pagination",
		clickable: true,
		bulletClass: "slider__pagination-item",
	},

    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
});

const jsSliderEquipmentThumbs = new Swiper('.jsSliderEquipmentThumbs .slider__inner', {
	modules: [Thumbs, FreeMode],
	slidesPerView: 'auto',
    speed: 500,
    direction: 'vertical',
    spaceBetween: 15,
    mousewheel: true,
    freeMode: true,

	breakpoints: {
		0:{
            direction: 'horizontal',
		},

        834: {
            direction: 'vertical',
		},

        992: {
            direction: 'horizontal',
        },

        1260: {
            direction: 'vertical',
		},
	}
});

const jsSliderEquipment = new Swiper('.jsSliderEquipment .slider__inner', {
	modules: [Thumbs, EffectFade, Mousewheel],
	slidesPerView: 1,
    speed: 500,
    mousewheel: true,

    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },

    thumbs: {
        swiper: jsSliderEquipmentThumbs
    }
});

document.addEventListener('DOMContentLoaded', function(){
    if(document.querySelector('.jsSliderEquipmentThumbs')) {
        let items = document.querySelectorAll('.jsSliderEquipmentThumbs .swiper-slide');
        for (let index = 0; index < items.length; index++) {
            const item = items[index];

            item.addEventListener('mouseover', function(){
                jsSliderEquipment.slideTo(index, 750)
            })
        }
    }
})

// window.onload = function (){

//     if(document.querySelector('.jsSliderBadges')){
//         let badges = document.querySelectorAll('.jsSliderBadges');

//         for (let index = 0; index < badges.length; index++) {
//             const badge = badges[index];

//             const slider = new Swiper( badge.querySelector('.slider__inner'), {
//                 modules: [FreeMode],
//                 loop: false,
//                 slidesPerView: "auto",
//                 // spaceBetween: 20,
//                 freeMode: true,
//             });

//             let slides = badge.querySelectorAll('.swiper-slide');

//             for (let index = 0; index < slides.length; index++) {
//                 const slide = slides[index];
//                 let badge = slide.querySelector('.badge')


//                 if(badge.querySelector('input[checked]')){
//                     update(index);
//                 }

//                 if(badge.classList.contains('jsTabLink') && badge.classList.contains('is-active')){
//                     update(index);
//                 }
//             }

//             function update(realIndex){
//                 slider.slideTo(realIndex, 0)
//             }
//         }
//     }

// }
