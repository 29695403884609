import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import Gator from 'gator';
import RangeService from './classes/RangeService.js';
import RangeRender from './classes/RangeRender.js';

const selectors = {
  component: '[data-component="range"]',
  slider: '[data-range="slider"]',
  input: '[data-range="input"]',
};

const rangeComponent = (node, userOptions = {}) => {
  const sliderNode = node.querySelector(selectors.slider);
  const sliderOptions = node.dataset.options ? JSON.parse(node.dataset.options) : userOptions;

  const {
    start, min, max, step, suffix, hideControls,
  } = sliderOptions;

  const normalizedConnectProp = start.length > 1 ? true : 'lower';
  const normalizedTooltipsProp = start.length > 1 ? [true, true] : [true];
  const normalizedSuffix = suffix ? ` ${suffix}` : '';

  const slider = noUiSlider.create(sliderNode, {
    start,
    range: {
      min: parseInt(min, 10),
      max: parseInt(max, 10),
    },
    connect: normalizedConnectProp,
    step: step ? parseInt(step, 10) : 1,
    tooltips: normalizedTooltipsProp,
    format: wNumb({
      decimals: 0,
      thousand: ' ',
      suffix: normalizedSuffix,
    }),
  });

  if (hideControls) {
    return;
  }

  const [inputFromNode, inputToNode] = Array.from(node.querySelectorAll(selectors.input));

  Gator(node).on('input', selectors.input, ({ target }) => {
    const { type } = target.dataset;

    RangeRender.maskedInput({
      inputNode: target,
      value: target.value,
    });

    RangeService.updateSlider({
      slider,
      type,
      value: +target.value,
    });
  });

  slider.on('update', (values, handle) => {
    const normalizedHandleType = handle === 0 ? 'from' : 'to';
    const value = values[handle];

    RangeRender.inputsValues({
      type: normalizedHandleType,
      value,
      inputFromNode,
      inputToNode,
    });
  });

  slider.on('start', (values, handle) => {
    const normalizedHandleType = handle === 0 ? 'from' : 'to';

    RangeRender.inputsStates({
      type: normalizedHandleType,
      state: 'focused',
      inputFromNode,
      inputToNode,
    });
  });

  slider.on('end', (values, handle) => {
    const normalizedHandleType = handle === 0 ? 'from' : 'to';

    RangeRender.inputsStates({
      type: normalizedHandleType,
      state: 'default',
      inputFromNode,
      inputToNode,
    });
  });
};

export default rangeComponent;
