import dragScroll from './drag-scroll';

const dragScrollInit = () =>{
    const nodes = Array.from(document.querySelectorAll('[data-component="drag-scroll"]'));

    nodes.map((node) => {
        const contentNode = node.querySelector('[data-node="content"]');

        if (!contentNode) return null;

        dragScroll(node)
    });
}

document.addEventListener('DOMContentLoaded', () => {
    dragScrollInit()
});

export default dragScrollInit;