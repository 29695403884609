import IMask from 'imask';

const createInput = (name, attrs = {}, maskOptions = {}) => {
  const box = document.createElement('label');
  const control = document.createElement('input');

  box.classList.add('input', 'input--white');
  control.classList.add('input__field');

  control.name = name;
  control.type = attrs.type || 'text';

  Object.entries(attrs).forEach(([key, value]) => {
    control.setAttribute(key, value);
  });

  if (Object.entries(maskOptions).length) {
    IMask(control, maskOptions);
  }

  box.append(control);

  return box;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  createInput,
};
