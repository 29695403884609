import noUiSlider from 'nouislider';

const scale = (slider, values, defaultValue) => {
  if (!slider || !Array.isArray(values) || !values.length) {
    return;
  }

  const format = {
    to: (value) => {
      return values[Math.round(value)];
    },
    from: (value) => {
      return values.indexOf(value);
    },
  };

  noUiSlider.create(slider, {
    start: defaultValue || values[0],
    range: { min: 0, max: values.length - 1 },
    step: 1,
    tooltips: true,
    format,
    pips: { mode: 'steps', format, density: 50 },
  });
};

const createScale = (name, values, defaultValue, id = '') => {
  const box = document.createElement('div');
  const sliderBox = document.createElement('div');
  const slider = document.createElement('div');
  const input = document.createElement('input');

  box.classList.add('range', 'range--white', 'range--scale');
  sliderBox.classList.add('range__slider-wrapper');
  slider.classList.add('range__slider');

  input.name = name;
  input.type = 'hidden';

  if (id) {
    slider.setAttribute('id', id);
  }

  box.append(sliderBox);
  sliderBox.append(slider);
  box.append(input);

  scale(slider, values, defaultValue);

  let cacheValue = null;
  slider.noUiSlider.on('update', (sliderValues, handle) => {
    const value = sliderValues[handle];

    input.value = slider.noUiSlider.options.format.from(value);

    if (cacheValue !== value) {
      slider.dispatchEvent(new Event('scale-change', { bubbles: true }));
      cacheValue = value;
    }
  });

  return box;
};

export default scale;
export {
  createScale,
};
