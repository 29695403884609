import $ from 'jquery';

import { apiConfig, breakpoints } from '@/core/config/js/index.js';

import {
  createBadge,
  createBadgesRow,
  createVisuallyBadge,
} from '@/core/ui/badge/index.js';
import { createScale } from '@/core/ui/scale/index.js';
import { createSelect } from '@/core/ui/select/index.js';
import { createInput } from '@/core/ui/input/index.js';

import { constructorDataLib } from '../features/index.js';

const createSection = (
  bodyContent,
  titleText,
  descText = '',
  attrs = {},
) => {
  const section = document.createElement('div');
  const prop = document.createElement('div');
  const header = document.createElement('div');
  const titleBox = document.createElement('div');
  const title = document.createElement('h3');
  const body = document.createElement('div');

  section.classList.add('constructor__row');
  prop.classList.add('constructor__prop');
  header.classList.add('constructor__prop-header');
  titleBox.classList.add('constructor__prop-title');
  body.classList.add('constructor__prop-body');

  section.append(prop);
  prop.append(header, body);
  header.append(titleBox);
  body.append(bodyContent);

  titleBox.append(title);
  title.textContent = titleText;

  if (descText) {
    const desc = document.createElement('p');
    desc.classList.add('text-md', 'constructor__prop-subtitle');
    desc.textContent = descText;
    header.append(desc);
  }

  Object.entries(attrs).forEach(([key, attrValue]) => {
    if (!attrValue) return;
    section.setAttribute(key, attrValue);
  });

  return section;
};

const createResultSection = (propData) => {
  const {
    icon,
    title,
    desc,
  } = propData;

  const section = document.createElement('div');
  const iconContent = icon
    ? `<img src="${apiConfig.assetsUrl}//${icon}.svg" alt="">` : '';
  const textContent = desc
    ? `${title}: <span>${desc}</span>` : title;

  section.classList.add('constructor-result__prop');

  section.innerHTML = `
    ${iconContent}
    <p>${textContent}</p>
  `;

  return section;
};

const createResultHeader = () => {
  const header = document.createElement('div');
  const headerTitle = document.createElement('h3');

  header.classList.add('constructor-result__header');
  headerTitle.classList.add('h3', 'constructor-result__title');

  headerTitle.textContent = 'Вы выбрали:';

  header.append(headerTitle);

  return header;
};

const createResultBody = () => {
  const body = document.createElement('div');
  const bodyProps = document.createElement('div');
  const bodyMonth = document.createElement('div');

  body.classList.add('constructor-result__body');
  bodyProps.classList.add('constructor-result__props');
  bodyMonth.classList.add('constructor-result__month');

  body.append(bodyProps, bodyMonth);

  return body;
};

const createResultFooter = () => {
  let isVisible = false;

  const footer = document.createElement('div');
  const footerTotal = document.createElement('div');
  const burger = document.createElement('button');
  const btnSubmit = document.createElement('button');

  btnSubmit.innerHTML = '<span class="button__text">Заказать</span>';
  burger.innerHTML = '<svg class="icon"><use xlink:href="#sort"></use></svg>';

  footer.classList.add('constructor-result__footer');
  footerTotal.classList.add('constructor-result__total');
  btnSubmit.classList.add('button', 'button--dark', 'constructor-result__submit');
  burger.classList.add('constructor__trigger');

  btnSubmit.type = 'submit';
  burger.type = 'button';

  footer.append(footerTotal, btnSubmit, burger);

  burger.addEventListener('click', (event) => {
    isVisible = !isVisible;

    const parenBlock = event.target.closest('.constructor-result');

    burger.innerHTML = isVisible ? '<svg class="icon"><use xlink:href="#close"></use></svg>' : '<svg class="icon"><use xlink:href="#sort"></use></svg>';

    parenBlock.classList.toggle('_shown');
  });

  return footer;
};

const createHeader = (titleText) => {
  const header = document.createElement('div');
  const title = document.createElement('h2');

  header.classList.add('constructor__header');
  title.classList.add('h2', 'constructor__title');
  title.textContent = titleText;

  header.append(title);

  return header;
};

const createBody = () => {
  const body = document.createElement('div');

  body.classList.add('constructor__body');

  return body;
};

const createParams = (state, params) => {
  const {
    activeCountryId,
    activeCpuId,
  } = state;

  const isDesktop = window.innerWidth > breakpoints.lg;
  const main = document.createDocumentFragment();

  // страны
  if (Array.isArray(params.countries) && params.countries.length) {
    const badges = params.countries.map((item) => {
      return createBadge(
        'country',
        'radio',
        item.id,
        item.title,
        {
          checked: activeCountryId && activeCountryId === item.id,
        },
      );
    });
    const badgesRow = createBadgesRow(badges);
    const block = createSection(badgesRow, 'Страна расположения');

    main.append(block);
  }

  // процессоры
  if (Array.isArray(params.cpu) && params.cpu.length) {
    const activeCpuList = params.cpu.filter((item) => {
      return activeCpuId && activeCpuId === item.id;
    });

    // список вариантов
    const badges = params.cpu
      .map((item) => {
        return createBadge(
          'cpu',
          'radio',
          item.id,
          item.title,
          {
            checked: activeCpuId && activeCpuId === item.id,
          },
        );
      });
    const badgesRow = createBadgesRow(badges);
    const block = createSection(badgesRow, 'Процессор');

    main.append(block);

    if (isDesktop) { // параметры процессора в виде шкалы
      // ядра
      activeCpuList.forEach((section) => {
        const valuesData = section.value && section.value.core ? section.value.core : [];

        const values = valuesData.map((item) => {
          return constructorDataLib.getMatrixPropFullValue(item);
        });

        const defaultValue = valuesData.reduce((acc, item, index) => {
          if (item.checked === 'Y') {
            return values[index];
          }

          return acc;
        }, values[0]);

        const scaleName = `core:${section.id}`;
        const scale = createScale(scaleName, values, defaultValue, 'core');
        const settingsBlock = createSection(scale, 'Кол-во ядер');

        main.append(settingsBlock);
      });

      // память
      activeCpuList.forEach((section) => {
        const valuesData = section.value && section.value.ram ? section.value.ram : [];

        const values = valuesData.map((item) => {
          return constructorDataLib.getMatrixPropFullValue(item);
        });

        const defaultValue = valuesData.reduce((acc, item, index) => {
          if (item.checked === 'Y') {
            return values[index];
          }

          return acc;
        }, values[0]);

        const scaleName = `ram:${section.id}`;
        const scale = createScale(scaleName, values, defaultValue, 'ram');
        const desc = section.ram_per_core && section.ram_per_core.min
          ? `не более ${section.ram_per_core.min} гб на 1 ядро` : '';
        const settingsBlock = createSection(scale, 'Оперативная память', desc);

        main.append(settingsBlock);
      });
    } else { // параметры процессора в виде селекта
      // ядра
      activeCpuList.forEach((section) => {
        const valuesData = section.value && section.value.core ? section.value.core : [];

        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: constructorDataLib.getMatrixPropFullValue(item),
            isSelected: item.checked === 'Y',
          };
        });

        const name = `core:${section.id}`;
        const select = createSelect(options, name);
        const settingsBlock = createSection(select, 'Кол-во ядер');

        main.append(settingsBlock);
      });

      // память
      activeCpuList.forEach((section) => {
        const valuesData = section.value && section.value.ram ? section.value.ram : [];

        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: constructorDataLib.getMatrixPropFullValue(item),
            isSelected: item.checked === 'Y',
          };
        });

        const name = `ram:${section.id}`;
        const select = createSelect(options, name);
        const desc = section.ram_per_core && section.ram_per_core.min
          ? `не более ${section.ram_per_core.min} гб на 1 ядро` : '';
        const settingsBlock = createSection(select, 'Оперативная память', desc);

        main.append(settingsBlock);
      });
    }
  }

  // хранилища
  if (Array.isArray(params.storage) && params.storage.length) {
    if (isDesktop) { // хранилища в виде шкалы
      const scales = document.createElement('div');
      scales.classList.add('constructor__prop-list');

      params.storage.forEach((section) => {
        const valuesData = section.value ? section.value : [];

        const values = valuesData.map((item) => {
          return constructorDataLib.getMatrixPropFullValue(item);
        });

        const defaultValue = valuesData.reduce((acc, item, index) => {
          if (item.checked === 'Y') {
            return values[index];
          }

          return acc;
        }, values[0]);

        const hasDefaultValueNum = defaultValue.replace(/\D/g, '') !== '';

        const scaleBox = document.createElement('div');
        const badgeTitle = section.title_short || section.title;
        const badge = createVisuallyBadge(badgeTitle, hasDefaultValueNum);
        const scale = createScale(section.id, values, defaultValue);

        scaleBox.classList.add('constructor__prop-body', '_included');
        scale.classList.add('range--storage');

        scaleBox.append(badge, scale);
        scales.append(scaleBox);

        const scaleSlider = scale.querySelector('.range__slider');
        scaleSlider.noUiSlider.on('update', () => {
          const value = scaleSlider.noUiSlider.get().replace(/\D/g, '');

          if (!value) {
            badge.classList.remove('is-active');
          }
        });

        scale.addEventListener('scale-change', () => {
          const value = scaleSlider.noUiSlider.get().replace(/\D/g, '');

          if (value) {
            badge.classList.add('is-active');
          }
        });

        badge.addEventListener('click', () => {
          const isActive = badge.classList.contains('is-active');
          const value = isActive ? values[0] : values[1];
          const hasValue = value.replace(/\D/g, '') !== '';

          scaleSlider.noUiSlider.set(value);

          if (hasValue) {
            badge.classList.add('is-active');
            return;
          }

          badge.classList.remove('is-active');
        });
      });

      const block = createSection(scales, 'Хранилища');
      block.classList.add('_storages');
      main.append(block);
    } else { // хранилища в виде селекта
      params.storage
        .forEach((section) => {
          const valuesData = section.value ? section.value : [];

          const options = valuesData.map((item, index) => {
            return {
              value: index,
              title: constructorDataLib.getMatrixPropFullValue(item),
              isSelected: item.checked === 'Y',
            };
          });
          const select = createSelect(options, section.id);
          const block = createSection(select, section.title);

          main.append(block);
        });
    }
  }

  // ос
  if (Array.isArray(params.os) && params.os.length) {
    params.os
      .forEach((section) => {
        const valuesData = section.value ? section.value : [];

        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: item.value,
            isSelected: item.checked === 'Y',
          };
        });
        const select = createSelect(options, section.id);
        const block = createSection(select, 'Предустановленная ОС');

        main.append(block);
      });
  }

  // по
  if (Array.isArray(params.pa) && params.pa.length) {
    params.pa
      .forEach((section) => {
        const valuesData = section.value ? section.value : [];
        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: item.value,
            isSelected: item.checked === 'Y',
          };
        });
        const select = createSelect(options, section.id);
        const block = createSection(select, 'Предустановленное ПО');

        main.append(block);
      });
  }

  // ipv4 адрес
  if (Array.isArray(params.ipv4Address) && params.ipv4Address.length) {
    params.ipv4Address
      .forEach((section) => {
        const input = createInput(
          'ipv4Address',
          {
            id: section.id,
            placeholder: 'Введите количество',
          },
          {
            mask: Number,
            min: 0,
            max: section.max || 1000,
            thousandsSeparator: ' ',
          },
        );
        const block = createSection(input, 'Дополнительный IPv4 адрес');

        main.append(block);
      });
  }

  // интернет
  if (Array.isArray(params.internet) && params.internet.length) {
    params.internet
      .forEach((section) => {
        const valuesData = section.value ? section.value : [];

        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: constructorDataLib.getMatrixPropFullValue(item),
            isSelected: item.checked === 'Y',
          };
        });
        const select = createSelect(options, section.id);
        const block = createSection(select, 'Интернет');

        main.append(block);
      });
  }

  // защита от ddos
  if (Array.isArray(params.ddos) && params.ddos.length) {
    params.ddos
      .forEach((section) => {
        const valuesData = section.value ? section.value : [];

        const options = valuesData.map((item, index) => {
          return {
            value: index,
            title: constructorDataLib.getMatrixPropFullValue(item),
            isSelected: item.checked === 'Y',
          };
        });
        const select = createSelect(options, section.id);
        const block = createSection(select, 'Защита от DDoS атак');

        main.append(block);
      });
  }

  // ндс и тп
  if (Array.isArray(params.nds) && params.nds.length) {
    const badges = params.nds
      .map((item) => {
        return createBadge(
          'nds',
          'checkbox',
          item.id,
          item.title,
        );
      });
    const badgesRow = createBadgesRow(badges);
    const block = createSection(badgesRow, 'Дополнительно');

    main.append(block);
  }

  return main;
};

const createResultParam = (paramData) => {
  const {
    title,
    value,
  } = paramData;

  const box = document.createElement('div');
  const textContent = value ? `${title}: <span>${value}</span>` : title;

  box.classList.add('constructor-result__prop');
  box.innerHTML = `<p>${textContent}</p>`;

  return box;
};

const createResultParams = (props) => {
  const main = document.createDocumentFragment();

  props
    .map((prop) => {
      const value = prop.type && prop.type === 'matrix'
        ? constructorDataLib.getMatrixPropFullValue(prop) : prop.title;

      return createResultParam({
        title: prop.param_title,
        value,
      });
    })
    .forEach((propBlock) => {
      main.append(propBlock);
    });

  return main;
};

const createResultEmailTemplates = (props) => {
  return props
    .map((prop) => {
      const value = prop.type && prop.type === 'matrix'
        ? constructorDataLib.getMatrixPropFullValue(prop) : prop.title;

      return `${prop.param_title}: ${value}`;
    }).join(';');
};

const replaceContent = (body, content) => {
  body.replaceChildren(content);
};

const renderPrice = (resultBlock, price) => {
  const totalBlock = resultBlock.querySelector('.constructor-result__total');

  totalBlock.innerHTML = `Итого: <span>${price}</span>`;
};

const deserializeForm = (form, entries, callback = false) => {
  entries.forEach(([key, value]) => {
    if (key === 'country' || key === 'cpu') {
      return;
    }

    const controlElement = form.querySelector(`[name="${key}"]`);

    if (controlElement) {
      // select
      if (controlElement.tagName === 'SELECT') {
        $(controlElement).val(value);
        $(controlElement).trigger('change');
        return;
      }

      // input
      switch (controlElement.type) {
        case 'checkbox':
          controlElement.checked = !!value;
          break;
        default: controlElement.value = value;
          break;
      }

      controlElement.dispatchEvent(new Event('change', { bubbles: true }));
    }
  });

  if (callback) {
    callback();
  }
};

export {
  createHeader,
  createBody,
  createParams,
  createResultEmailTemplates,
  createResultParams,
  createResultHeader,
  createResultBody,
  createResultFooter,
  createResultSection,
  createSection,
  replaceContent,
  renderPrice,
  deserializeForm,
};
