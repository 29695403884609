const removeEmptyProps = (props) => {
  if (!Array.isArray(props) || !props.length) {
    return [];
  }

  const result = props.filter((prop) => {
    const { value } = prop;
    if (Array.isArray(value)) {
      return !!value.length;
    }

    return true;
  });

  return result;
};

const clearParams = (params) => {
  const paramsEntries = Object.entries(params);

  const cleanParamsEntries = paramsEntries.reduce((acc, [key, value]) => {
    const cleanProps = removeEmptyProps(value);
    const newParam = [key, cleanProps];
    const newAcc = [...acc, newParam];

    return newAcc;
  }, []);

  const result = Object.fromEntries(cleanParamsEntries);

  return result;
};

const setPropsDefaultValue = (props) => {
  if (!Array.isArray(props) || !props.length) {
    return [];
  }

  const defaultValue = props.reduce((acc, item, index) => {
    if (item.checked === 'Y') {
      return index;
    }

    return acc;
  }, 0);

  const result = props.map((item, index) => {
    return index === defaultValue ? { ...item, checked: 'Y' } : { ...item, checked: 'N' };
  });

  return result;
};

const setParamsDefaultValue = (params) => {
  const paramsEntries = Object.entries(params);

  const resultParamsEntries = paramsEntries.reduce((acc, [key, values]) => {
    const newProps = values.map((valueData) => {
      if (key === 'cpu' && valueData.value) {
        const newCoreValue = setPropsDefaultValue(valueData.value.core);
        const newRamValue = setPropsDefaultValue(valueData.value.ram);

        return {
          ...valueData,
          value: {
            core: newCoreValue,
            ram: newRamValue,
          },
        };
      }

      if (Array.isArray(valueData.value)) {
        const newValue = setPropsDefaultValue(valueData.value);

        return {
          ...valueData,
          value: newValue,
        };
      }
      return valueData;
    });
    const newParam = [key, newProps];
    const newAcc = [...acc, newParam];

    return newAcc;
  }, []);

  const result = Object.fromEntries(resultParamsEntries);

  return result;
};

const filterPropsByCountry = (props, filterCountry) => {
  return props.filter((item) => {
    return !filterCountry || !item.country || item.country.includes(filterCountry);
  });
};

const findPropByCountry = (props, filterCountry, index) => {
  const filteredProps = filterPropsByCountry(props, filterCountry);

  return filteredProps[index];
};

const getCheckedProp = (props) => {
  if (!Array.isArray(props) || !props.length) {
    return [];
  }

  const checkedProps = props.filter((prop) => {
    return prop.checked === 'Y';
  });

  return checkedProps[0];
};

const setCheckedPropById = (props, id) => {
  if (!Array.isArray(props) || !props.length) {
    return [];
  }

  return props.map((prop) => {
    return {
      ...prop,
      checked: prop.id === id ? 'Y' : 'N',
    };
  });
};

const filterParamsByCountry = (params, filterCountry) => {
  const paramsEntries = Object.entries(params);

  const filteredParamsEntries = paramsEntries.reduce((acc, [key, value]) => {
    const filteredProps = filterPropsByCountry(value, filterCountry);
    const newParam = [key, filteredProps.length ? filteredProps : []];
    const newAcc = [...acc, newParam];

    return newAcc;
  }, []);

  const result = Object.fromEntries(filteredParamsEntries);

  return result;
};

const getParamById = (params, id) => {
  const paramsEntries = Object.entries(params);

  const filteredParams = paramsEntries
    .map(([, arParams]) => {
      return arParams;
    })
    .flat()
    .filter((param) => {
      return param.id === id;
    });

  const result = filteredParams[0];

  return result;
};

const parsePropName = (name) => {
  const codeAndIdRegExp = /^(\w+):(\d+)$/;
  const codeRegExp = /^(\D+)$/;
  const idRegExp = /^(\d+)$/;

  const result = {
    code: '',
    id: '',
  };

  if (codeAndIdRegExp.test(name)) {
    const [, code, id] = name.match(codeAndIdRegExp);

    result.code = code;
    result.id = id;
  }

  if (codeRegExp.test(name)) {
    result.code = name;
  }

  if (idRegExp.test(name)) {
    result.id = name;
  }

  return result;
};

const getPropValue = (paramsData, paramName, propIndex) => {
  const { code, id } = parsePropName(paramName);
  const param = getParamById(paramsData, id);

  if (!param || !param.value || typeof param.value === 'string') {
    return false;
  }

  const propValue = code ? param.value[code][propIndex] : param.value[propIndex];
  const result = propValue ? { type: 'matrix', ...propValue } : false;

  return result;
};

const getPropsValue = (paramsData, formData) => {
  const arFormData = [...formData.entries()];

  return arFormData.reduce((acc, [paramName, propIndex]) => {
    const result = getPropValue(paramsData, paramName, propIndex);

    return result ? [...acc, result] : acc;
  }, []);
};

// кол-во ядер
// кол-во рам
// хранилища
// ос
// по
// интернет
// защита от DDoS атак
const calcPropsValuesPrice = (paramsData, formData) => {
  const values = getPropsValue(paramsData, formData);

  return values.reduce((acc, propValue) => {
    let sum = 0;

    const price = propValue.price ? +propValue.price : 0;
    const priceOnce = propValue.price_once ? +propValue.price_once : 0;

    if (price && !Number.isNaN(price)) {
      sum += price;
    }

    if (priceOnce && !Number.isNaN(priceOnce)) {
      sum += priceOnce;
    }

    const newAcc = acc + sum;

    return newAcc;
  }, 0);
};

const calcCpuPrice = (paramsList, paramId) => {
  const cpu = getParamById(paramsList, paramId);
  let sum = 0;

  if (!cpu) {
    return sum;
  }

  const { price, price_once: priceOnce } = cpu;

  if (price && !Number.isNaN(price)) {
    sum += price;
  }

  if (priceOnce && !Number.isNaN(priceOnce)) {
    sum += priceOnce;
  }

  return sum;
};

const calcAddressPrice = (paramsList, paramId, count) => {
  const cpu = getParamById(paramsList, paramId);
  let sum = 0;

  if (!cpu) {
    return sum;
  }

  const { price, price_once: priceOnce } = cpu;

  if (price && !Number.isNaN(price)) {
    sum += price * count;
  }

  if (priceOnce && !Number.isNaN(priceOnce)) {
    sum += priceOnce;
  }

  return sum;
};

const getMatrixPropFullValue = (prop) => {
  if (!prop) {
    return false;
  }

  if (prop.value && prop.unit) {
    return `${prop.value} ${prop.unit}`;
  }

  if (!prop.value) {
    console.debug('заполните значение свойства:', prop);
  }

  return prop.value ? prop.value : 0;
};

export {
  removeEmptyProps,
  clearParams,
  filterPropsByCountry,
  filterParamsByCountry,
  findPropByCountry,
  setPropsDefaultValue,
  setParamsDefaultValue,
  getCheckedProp,
  setCheckedPropById,
  getParamById,
  parsePropName,
  calcPropsValuesPrice,
  calcCpuPrice,
  calcAddressPrice,
  getPropValue,
  getPropsValue,
  getMatrixPropFullValue,
};
