import $ from 'jquery';
import 'select2';

const select = (node) => {
  const $select = $(node);
  const placeholder = $select.attr('placeholder');
  const selectBox = $select.closest('.select');
  const hasBlueTheme = selectBox.get(0).classList.contains('select--blue');
  const theme = hasBlueTheme ? 'select--blue' : '';

  $select.select2({
    width: '100%',
    placeholder,
    allowClear: true,
    dropdownCssClass: theme,
  });

  $select.on('select2:open', () => {
    selectBox.addClass('is-focus');
  });

  $select.on('select2:close', () => {
    selectBox.removeClass('is-focus');
  });

  $select.on('select2:select', () => {
    selectBox.addClass('is-filled');
    selectBox[0].dispatchEvent(new Event('select-change', { bubbles: true }));
  });

  return $select;
};

const createSelect = (options, name) => {
  const box = document.createElement('div');
  const control = document.createElement('select');
  const optionsElements = options.map(({ value, title, isSelected }) => {
    const element = document.createElement('option');
    element.value = value;
    element.selected = isSelected;
    element.textContent = title;
    return element;
  });

  box.classList.add('select', 'select--blue');

  control.name = name;

  box.append(control);
  control.append(...optionsElements);

  select(control);

  return box;
};

export default select;

export {
  createSelect,
};
