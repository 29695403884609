import { clearParams } from './lib/index.js';
import { getParams, sendParams } from './api/index.js';

const DEFAULT_TITLE = 'Конструктор сервера';

const getData = async (id) => {
  const data = await getParams(id);
  const title = data.title || DEFAULT_TITLE;
  const countries = Array.isArray(data.country) ? [...data.country] : [];
  const params = clearParams(data.params);

  return {
    title,
    countries,
    params,
  };
};

const sendData = async (payload) => {
  const result = await sendParams(payload);

  if (!result) {
    alert('Произошла ошибка, перезагрузите страницу и попробуйте снова');
    return false;
  }

  return result;
};

export {
  getData as getConstructorData,
  sendData as sendConstructorData,
};

export * as constructorDataLib from './lib/index.js';
