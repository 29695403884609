
const initComponent = (componentNode) => {
    let block = componentNode;
    let links = block.querySelectorAll('[data-tab="link"]');
    let items = block.querySelectorAll('[data-tab="item"]');
	let activeClass = 'is-active'

    const checkActveLink = () => {
        if (!block.querySelector(`[data-tab="link"].${activeClass}`)){
            block.querySelectorAll('[data-tab="link"]')[0].classList.add(activeClass);
        }

        let activeLink = block.querySelector(`[data-tab="link"].${activeClass}`);
        let activeIndex = activeLink.getAttribute('data-tab-id');
        
        if(block.querySelector('[data-tab="item"][data-tab-id="'+ activeIndex +'"]')){
            block.querySelector('[data-tab="item"][data-tab-id="'+ activeIndex +'"]').classList.add(activeClass);
        }
    }

	const initTabs = () => {
		const initGroup = (group) => {
			group.forEach((groupItem, index) => {
                groupItem.setAttribute('data-tab-id', index);
			})
		}

        initGroup(links);
        initGroup(items);
        checkActveLink();
	}
 
    const clear = () => {
		links.forEach(link => {
            link.classList.remove(activeClass);
		})
		
		items.forEach(item => {
            item.classList.remove(activeClass);
		})
    }

    initTabs()

	links.forEach((link, index) => {
		link.onclick = () => {
			clear()
            link.classList.add(activeClass);

            if(block.querySelector('[data-tab="item"][data-tab-id="'+ index +'"]')){
                block.querySelector('[data-tab="item"][data-tab-id="'+ index +'"]').classList.add(activeClass);
            }
		}

        if(links.length < 2){
            link[0].classList.add(activeClass);

            if(block.querySelector('[data-tab="item"][data-tab-id="'+ link[0].getAttribute('data-tab-id') +'"]')){
                block.querySelector('[data-tab="item"][data-tab-id="'+ link[0].getAttribute('data-tab-id') +'"]').classList.add(activeClass);
            }
        }
    })

}  

const initTabs = () => {
    const componentNodes = Array.from(document.querySelectorAll('[data-tab="group"]'));

    componentNodes.forEach(initComponent);
}

document.addEventListener('DOMContentLoaded', () => {
	initTabs()
})

export default initTabs;