const DEV_API_URL = 'https://smartlk.bitrix.spiks.dev/api/';
const PROD_API_URL = '/api/';
const API_URL = process.env.NODE_ENV === 'development' ? DEV_API_URL : PROD_API_URL;

const DEV_ASSETS_URL = 'assets/media';
const PROD_ASSETS_URL = '/local/templates/smartelecom/front/main/assets/media';
const ASSETS_URL = process.env.NODE_ENV === 'development' ? DEV_ASSETS_URL : PROD_ASSETS_URL;

const api = {
  baseUrl: API_URL,
  assetsUrl: ASSETS_URL,
};

export default api;
