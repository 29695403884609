import { apiInstance } from '@/core/api/index.js';

const HOST_URL = '/constructor/server';

const getParams = async (id) => {
  try {
    const res = await apiInstance.get(
      `${HOST_URL}/get/`,
      {
        params: {
          id,
        },
      },
    );
    return res.data;
  } catch (e) {
    return e;
  }
};

const sendParams = async (formData) => {
  try {
    const res = await apiInstance.post(
      `${HOST_URL}/send/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (e) {
    return e;
  }
};

export {
  getParams,
  sendParams,
};
