const createBadge = (name, type, value, label, attrs = {}) => {
  const box = document.createElement('label');
  const control = document.createElement('input');
  const content = document.createElement('div');
  const title = document.createElement('span');

  box.classList.add('badge');
  control.classList.add('badge__input');
  content.classList.add('badge__wrapper');

  title.textContent = label;

  control.type = type;
  control.name = name;
  control.value = value;

  Object.entries(attrs).forEach(([key, attrValue]) => {
    if (!attrValue) return;
    control.setAttribute(key, attrValue);
  });

  box.append(control, content);
  content.append(title);

  return box;
};

const createVisuallyBadge = (label, isActive = false) => {
  const box = document.createElement('div');
  const control = document.createElement('div');
  const content = document.createElement('div');
  const title = document.createElement('span');

  box.classList.add('badge');

  if (isActive) {
    box.classList.add('is-active');
  }

  control.classList.add('badge__input');
  content.classList.add('badge__wrapper');

  title.textContent = label;

  box.append(control, content);
  content.append(title);

  return box;
};

const createBadgesRow = (badges, showBorder = false) => {
  const box = document.createElement('div');
  const list = document.createElement('div');

  box.classList.add('badges', 'badges--white', showBorder ? 'badges--group' : null);
  list.classList.add('badges__list');

  box.append(list);
  list.append(...badges);

  return box;
};

export {
  createBadge,
  createBadgesRow,
  createVisuallyBadge,
};
