window.addEventListener('load', () => {
  if (!document.querySelector('.personal .input__field-textarea')) {
    return false;
  } else {
    const textarea = document.querySelector('.input__field-textarea');

    textarea.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = `${this.scrollHeight}px`;
    });
  }
});
