import ScrollBooster from 'scrollbooster';

const options = {
  scrollMode: 'transform',
  direction: 'horizontal',
};

const dragScroll = (componentNode) => {
  if (!componentNode) {
    console.debug('dragScrollComponent: containerNode is undefined');
  }

  const contentNode = componentNode.querySelector('[data-node="content"]');

  const sb = new ScrollBooster({
    ...options,
    viewport: componentNode,
    content: contentNode,
  });

  return sb;
};

export default dragScroll;