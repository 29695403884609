const inputFileComponent = (node) => {
    const component = node;
    const input  = component.querySelector('[data-input-file="input"]')
    const content  = component.querySelector('[data-input-file="content"]')

    input.onchange = () => {
        if(input.files.length != 0){
            clear()

            component.classList.add('is-upload');
            
            Array.from(input.files).forEach(item => {
                addFile(item.name)
            })
        } else{
            component.classList.add('is-remove');
            clear()
        }
    }

    const clear = () => {
        content.innerHTML = ``;
    }

    const addFile = (name) => {
        let item = document.createElement('div');
        item.classList.add('input-file__item');
        item.setAttribute('data-input-file', 'item');
        item.innerHTML = `
        <button class="input-file__delete" type="button" data-input-file="delete">
            <svg class="icon">
                <use xlink:href="#close"></use>
            </svg>
        </button>
        <span class="input-file__name" data-input-file="name">
            ${name}
        </span>`;

        item.querySelector('[data-input-file="delete"]').addEventListener('click', (e) =>{
            e.preventDefault()
            input.value = null;

            clear()
        })

        content.append(item);
    }
}

const inputFileInit = () => {
    let inputs = Array.from(document.querySelectorAll('[data-component="input-file"]'));

    inputs.forEach(item => {
        inputFileComponent(item);
    });
}

document.addEventListener('DOMContentLoaded', () => {
    inputFileInit()
})

export default inputFileInit;