const initCardService = (component) => {
	const body = component.querySelector('[data-more="body"]');
	const content = component.querySelector('[data-more="content"]');
	const trigger = component.querySelector('[data-more="trigger"]');
  
	if (!body) {
		return;
	}

	if(content.offsetHeight > body.offsetHeight) {
		trigger.classList.add('is-visible')
	} else{
		trigger.classList.remove('is-visible')
	}
  
	trigger.onclick = () => {
	  	component.classList.toggle('is-active');
	  	trigger.classList.toggle('is-swapped');
  
		if (component.classList.contains('is-active')) {
			body.style.maxHeight = content.offsetHeight + 'px';
		} else {
			body.style.maxHeight = '';
		}
	}
  
	if (component.classList.contains('is-active')) {
		body.style.maxHeight = content.offsetHeight + 'px';
	} else {
	  	body.style.maxHeight = '';
	}
};
  
const initCardServices = () => {
	const components = document.querySelectorAll('[data-more="component"]');

	components.forEach(initCardService);
}

document.addEventListener('DOMContentLoaded', () => {
	initCardServices();
});
  
export default initCardServices;
  