const elementMap = new Map();

const domData = {
  set(element, key, instance = true) {
    if (!element) {
      return false;
    }

    if (!elementMap.has(element)) {
      elementMap.set(element, new Map())
    }

    const instanceMap = elementMap.get(element);

    if (instanceMap.has(key) && instanceMap.size !== 0) {
      return false;
    }

    instanceMap.set(key, instance);

    return true;
  },

  remove(element, key) {
    if (!elementMap.has(element)) {
      return false;
    }

    const instanceMap = elementMap.get(element)

    instanceMap.delete(key)

    if (instanceMap.size === 0) {
      elementMap.delete(element)
    }

    return true;
  },
}

export default domData;
