const breakpoints = {
  sm: 575,
  md: 833,
  lg: 1023,
  xl: 1259,
  xxl: 1439,
  xxxl: 1679,
  xxxxl: 1919,
};

export default breakpoints;
