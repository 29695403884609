import $ from 'jquery';

import { breakpoints } from '@/core/config/js/index.js';
import { debounce } from '@/core/lib/js/index.js';

import {
  getConstructorData,
  sendConstructorData,
  constructorDataLib,
} from './features/index.js';

import {
  createHeader,
  createBody,
  createParams,
  createResultHeader,
  createResultBody,
  createResultFooter,
  createResultParams,
  createResultEmailTemplates,
  replaceContent,
  renderPrice,
  deserializeForm,
} from './ui/index.js';

const DEFAULT_CURRENCY = '₽';
const RESIZE_HANDLER_DELAY = 300;
const phoneRegExp = /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/;

const constructor = async (element, mainFormBlock) => {
  if (!element || !mainFormBlock) {
    return;
  }

  const constructorFormBlock = element.querySelector('form');
  const mainSlot = element.querySelector('[data-slot="main"]');
  const resultSlot = element.querySelector('[data-slot="result"]');

  const { id } = element.dataset;

  const {
    params,
    countries,
    title: mainSlotTitle,
  } = await getConstructorData(id);

  const mainFormState = {
    isValid: true,
    name: '',
    phone: '',
  };

  const headerBlock = createHeader(mainSlotTitle);
  const bodyBlock = createBody();
  const resultHeaderBlock = createResultHeader();
  const resultBodyBlock = createResultBody();
  const resultFooterBlock = createResultFooter();
  const resultParamsBlock = resultBodyBlock.querySelector('.constructor-result__props');

  const makeInitialState = () => {
    const state = {
      activeCountryId: '',
      activeCurrency: DEFAULT_CURRENCY,
      activeCpuId: '',
      activeCpu: {},
      activeCore: '',
      activeRam: '',
      activeNdsId: '',
      activeAddressesId: '',
      addressesCount: 0,
      totalPrice: 0,
    };

    if (Array.isArray(countries)) {
      const activeCountry = countries.reduce((acc, item) => {
        if (!acc && item.id) {
          return item;
        }

        return acc;
      }, false);

      state.activeCountryId = activeCountry && activeCountry.id
        ? activeCountry.id : '';
      state.activeCurrency = activeCountry && activeCountry.currencySymbol
        ? activeCountry.currencySymbol : DEFAULT_CURRENCY;
    }

    if (state.activeCountryId) {
      const activeCpu = constructorDataLib
        .findPropByCountry(params.cpu, state.activeCountryId, 0);
      state.activeCpuId = activeCpu && activeCpu.id ? activeCpu.id : '';
      state.activeCpu = activeCpu;
    }

    if (!state.activeCpuId && Array.isArray(params.cpu)) {
      state.activeCpuId = params.cpu.reduce((acc, item) => {
        if (!acc && item.id) {
          state.activeCpu = item;
          return item.id;
        }

        return acc;
      }, '');
    }

    return state;
  };

  const state = makeInitialState();

  let cache = [];

  if (!state.activeCpuId) {
    console.debug('неправильно заполнены процессоры в конструкторе с id ', id);
    return;
  }

  let activeParamsData = state.activeCountryId ? constructorDataLib
    .filterParamsByCountry(params, state.activeCountryId) : params;

  const getActiveValues = () => {
    const formData = new FormData(constructorFormBlock);
    const propsValues = constructorDataLib.getPropsValue(params, formData);
    const country = constructorDataLib.getParamById(countries, state.activeCountryId);
    const cpu = constructorDataLib.getParamById(params.cpu, state.activeCpuId);
    const ipv4Address = constructorDataLib
      .getParamById(params.ipv4Address, state.activeAddressesId);
    const extendedIpv4Address = ipv4Address
      ? { ...ipv4Address, title: state.addressesCount } : ipv4Address;
    const nds = constructorDataLib.getParamById(params.nds, state.activeNdsId);
    const resultParams = [country, cpu, ...propsValues, extendedIpv4Address, nds].filter(Boolean);

    return resultParams;
  };

  const updatePrice = () => {
    const formData = new FormData(constructorFormBlock);
    const propsPrice = constructorDataLib.calcPropsValuesPrice(params, formData);
    const cpuPrice = constructorDataLib.calcCpuPrice(params.cpu, state.activeCpuId);
    const addressPrice = constructorDataLib
      .calcAddressPrice(params.ipv4Address, state.activeAddressesId, state.addressesCount);
    const nds = constructorDataLib.getParamById(params.nds, state.activeNdsId);
    const kf = nds && nds.kf && !Number.isNaN(nds.kf) ? +nds.kf : 1;
    const totalPrice = Math.ceil((propsPrice + cpuPrice + addressPrice) * kf);

    state.totalPrice = totalPrice;

    renderPrice(resultSlot, `${state.totalPrice} ${state.activeCurrency}`);
  };

  const updateParamsView = () => {
    state.activeNdsId = '';
    state.addressesCount = 0;
    state.activeAddressesId = '';

    const newParamsBlocks = createParams(state, { ...activeParamsData, countries }, element);

    replaceContent(
      bodyBlock,
      newParamsBlocks,
    );
  };

  const updateResultParams = () => {
    const newResultParams = getActiveValues();
    const resultParamsBlocks = createResultParams(newResultParams);
    replaceContent(resultParamsBlock, resultParamsBlocks);
  };

  const calcCpuSettingsValues = (controlNameToChange) => {
    try {
      const isDesktop = window.innerWidth > breakpoints.lg;

      const minRamPerCore = +state.activeCpu.ram_per_core.min;
      const maxRamPerCore = +state.activeCpu.ram_per_core.max;

      const coreValues = state.activeCpu.value.core;
      const ramValues = state.activeCpu.value.ram;

      if (isDesktop) {
        const coreControl = bodyBlock.querySelector('input[name^="core"]');
        const ramControl = bodyBlock.querySelector('input[name^="ram"]');
        const coreScaleBox = coreControl.closest('.range');
        const ramScaleBox = ramControl.closest('.range');
        const coreScaleBlock = coreScaleBox.querySelector('.range__slider');
        const ramScaleBlock = ramScaleBox.querySelector('.range__slider');

        const coreScale = coreScaleBlock.noUiSlider;
        const ramScale = ramScaleBlock.noUiSlider;

        const coreValue = +coreScale.get().replace(/\D+/g, '');
        const ramValue = +ramScale.get().replace(/\D+/g, '');

        const getScaleMinValue = (values, minValue) => {
          const expectedValue = values.find(({ value }) => {
            return +value >= minValue;
          });

          const newValue = expectedValue || values[values.length - 1];
          const newFullValue = constructorDataLib.getMatrixPropFullValue(newValue);

          return newFullValue;
        };

        if (controlNameToChange === 'core') {
          const minValue = Math.ceil(ramValue / maxRamPerCore);

          if (coreValue < minValue) {
            const scaleMinValue = getScaleMinValue(coreValues, minValue);
            coreScale.set(scaleMinValue);
          }
        }

        if (controlNameToChange === 'ram') {
          const minValue = Math.ceil(coreValue * minRamPerCore);

          if (ramValue < minValue) {
            const scaleMinValue = getScaleMinValue(ramValues, minValue);
            ramScale.set(scaleMinValue);
          }
        }
      } else {
        const coreControl = bodyBlock.querySelector('select[name^="core"]');
        const ramControl = bodyBlock.querySelector('select[name^="ram"]');

        const coreValueStr = $(coreControl).val().replace(/\D+/g, '');
        const ramValueStr = $(ramControl).val().replace(/\D+/g, '');

        const coreValue = +coreValueStr;
        const ramValue = +ramValueStr;

        const getScaleMinValue = (values, minValue) => {
          const expectedValue = values.reduce((resultIndex, { value }, index) => {
            if (+value >= minValue && !resultIndex) {
              return index;
            }

            return resultIndex;
          }, 0);

          return expectedValue;
        };

        if (controlNameToChange === 'core') {
          const minValue = Math.ceil(ramValue / maxRamPerCore);

          if (coreValue < minValue) {
            const scaleMinValue = getScaleMinValue(coreValues, minValue);
            $(coreControl).val(scaleMinValue);
            $(coreControl).trigger('change');
          }
        }

        if (controlNameToChange === 'ram') {
          const minValue = Math.ceil(coreValue * minRamPerCore);
          if (ramValue < minValue) {
            const scaleMinValue = getScaleMinValue(ramValues, minValue);
            $(ramControl).val(scaleMinValue);
            $(ramControl).trigger('change');
          }
        }
      }
    } catch (e) {
      console.debug(e.message);
    }
  };

  const updateCacheValues = () => {
    let wasUpdated = false;
    const formData = [...new FormData(constructorFormBlock).entries()];

    cache = cache.map((item) => {
      const [country, cpu] = item;

      if (state.activeCountryId === country && state.activeCpuId === cpu) {
        wasUpdated = true;
        return [country, cpu, formData];
      }

      return item;
    });

    if (!wasUpdated) {
      cache.push([state.activeCountryId, state.activeCpuId, formData]);
    }
  };

  const getCacheValues = () => {
    const expectedEntities = cache.filter(([country, cpu]) => {
      return state.activeCountryId === country && state.activeCpuId === cpu;
    });

    if (expectedEntities.length) {
      const [entity] = expectedEntities;
      const [, , formDta] = entity;

      return formDta;
    }

    return [];
  };

  const setFormCacheValues = () => {
    const isDesktop = window.innerWidth > breakpoints.lg;
    const cacheValues = getCacheValues();

    if (isDesktop) {
      const reflowScales = () => {
        const scalesBlocks = Array.from(bodyBlock.querySelectorAll('.range'));

        scalesBlocks.forEach((scaleBlock) => {
          const input = scaleBlock.querySelector('input');
          const slider = scaleBlock.querySelector('.range__slider');
          const scale = slider.noUiSlider;
          const indexOfValue = input.value;
          const paramName = input.name;
          const activeProp = constructorDataLib.getPropValue(params, paramName, indexOfValue);
          const newValue = constructorDataLib.getMatrixPropFullValue(activeProp);
          scale.set(newValue);
        });
      };

      deserializeForm(constructorFormBlock, cacheValues, reflowScales);
      return;
    }

    deserializeForm(constructorFormBlock, cacheValues);
  };

  const setCpuSettingsCacheValues = (previousCacheValue) => {
    const isDesktop = window.innerWidth > breakpoints.lg;
    const cpuSettingsValues = previousCacheValue
      .filter(([paramName]) => {
        return /^(core|ram):(\d+)$/.test(paramName);
      })
      .map(([paramName, indexOfValue]) => {
        const prop = constructorDataLib.getPropValue(params, paramName, indexOfValue);
        return constructorDataLib.getMatrixPropFullValue(prop);
      });

    if (cpuSettingsValues.length < 2) {
      return;
    }

    const [expectedCoreValue, expectedRamValue] = cpuSettingsValues;
    const cpu = constructorDataLib.getParamById(params, state.activeCpuId);
    const coreValues = cpu && cpu.value && cpu.value.core ? cpu.value.core : [];
    const ramValues = cpu && cpu.value && cpu.value.ram ? cpu.value.ram : [];

    if (isDesktop) {
      const hasExpectedCoreValue = coreValues.find((prop) => {
        const expectedValueNum = expectedCoreValue.replace(/[^0-9]/g, '');
        return expectedValueNum === prop.value;
      });
      const hasExpectedRamValue = ramValues.find((prop) => {
        const expectedValueNum = expectedRamValue.replace(/[^0-9]/g, '');
        return expectedValueNum === prop.value;
      });

      if (hasExpectedCoreValue) {
        const control = bodyBlock.querySelector('input[name^="core"]');
        const scaleBox = control.closest('.range');
        const scaleBlock = scaleBox.querySelector('.range__slider');
        const scale = scaleBlock.noUiSlider;

        scale.set(expectedCoreValue);
      }

      if (hasExpectedRamValue) {
        const control = bodyBlock.querySelector('input[name^="ram"]');
        const scaleBox = control.closest('.range');
        const scaleBlock = scaleBox.querySelector('.range__slider');
        const scale = scaleBlock.noUiSlider;

        scale.set(expectedRamValue);
      }
    } else {
      const expectedCoreValueIndex = coreValues.findIndex((prop) => {
        const expectedValueNum = expectedCoreValue.replace(/[^0-9]/g, '');
        return expectedValueNum === prop.value;
      });
      const expectedRamValueIndex = ramValues.findIndex((prop) => {
        const expectedValueNum = expectedRamValue.replace(/[^0-9]/g, '');
        return expectedValueNum === prop.value;
      });

      if (expectedCoreValueIndex >= 0) {
        const control = bodyBlock.querySelector('select[name^="core"]');

        $(control).val(expectedCoreValueIndex);
        $(control).trigger('change');
      }

      if (expectedRamValueIndex >= 0) {
        const control = bodyBlock.querySelector('select[name^="ram"]');

        $(control).val(expectedRamValueIndex);
        $(control).trigger('change');
      }
    }
  };

  const onCountryChange = (event) => {
    const input = event.target.closest('input[name="country"]');

    if (input && input.checked) {
      updateCacheValues();

      const countryId = input.value;
      const country = constructorDataLib.getParamById(countries, countryId);
      const newActiveCpu = constructorDataLib.findPropByCountry(params.cpu, countryId, 0);

      activeParamsData = constructorDataLib.filterParamsByCountry(params, countryId);

      state.activeCountryId = countryId;
      state.activeCurrency = country && country.currencySymbol
        ? country.currencySymbol : DEFAULT_CURRENCY;
      state.activeCpuId = newActiveCpu && newActiveCpu.id ? newActiveCpu.id : '';
      state.activeCpu = newActiveCpu;

      if (!state.activeCpuId) {
        console.debug(`неправильно заполнены процессоры для страны с id ${countryId}`);
        return;
      }

      updateParamsView();
      setFormCacheValues();
      updateResultParams();
      updatePrice();
    }
  };

  const onCpuChange = (event) => {
    const input = event.target.closest('input[name="cpu"]');

    if (input && input.checked) {
      updateCacheValues();
      const previousCacheValue = getCacheValues();

      const cpuId = input.value;

      state.activeCpuId = cpuId;
      state.activeCpu = constructorDataLib.getParamById(params, cpuId);

      updateParamsView();
      setFormCacheValues();
      setCpuSettingsCacheValues(previousCacheValue);
      updateResultParams();
      updatePrice();
    }
  };

  const onAddressesChange = (event) => {
    const input = event.target.closest('input[name="ipv4Address"]');

    if (input) {
      const paramValue = input.value ? +input.value : 0;
      const paramId = paramValue ? input.id : '';

      state.addressesCount = paramValue;
      state.activeAddressesId = paramId;

      updateResultParams();
      updatePrice();
    }
  };

  const onNdsChange = (event) => {
    const input = event.target.closest('input[name="nds"]');

    if (input) {
      const isChecked = input.checked;
      const paramId = isChecked ? input.value : '';

      state.activeNdsId = paramId;

      updateResultParams();
      updatePrice();
    }
  };

  const onSelectChange = (event) => {
    const control = event.target.closest('.select');

    if (!control) {
      return;
    }

    const isCpuCoreControl = control.querySelector('select[name^="core"]');
    const isCpuRamControl = control.querySelector('select[name^="ram"]');

    if (isCpuCoreControl || isCpuRamControl) {
      let controlNameToChange = '';

      if (isCpuCoreControl) {
        controlNameToChange = 'ram';
      }

      if (isCpuRamControl) {
        controlNameToChange = 'core';
      }

      calcCpuSettingsValues(controlNameToChange);
    }

    updateResultParams();
    updatePrice();
  };

  const onScaleChange = (event) => {
    const control = event.target.closest('.range');

    if (!control) {
      return;
    }

    const isCpuCoreControl = control.querySelector('input[name^="core"]');
    const isCpuRamControl = control.querySelector('input[name^="ram"]');

    if (isCpuCoreControl || isCpuRamControl) {
      let controlNameToChange = '';
      if (isCpuCoreControl) {
        controlNameToChange = 'ram';
      }
      if (isCpuRamControl) {
        controlNameToChange = 'core';
      }
      calcCpuSettingsValues(controlNameToChange);
    }
    updateResultParams();
    updatePrice();
  };

  const onInit = () => {
    mainSlot.append(headerBlock);
    mainSlot.append(bodyBlock);
    resultSlot.append(resultHeaderBlock, resultBodyBlock, resultFooterBlock);

    updateParamsView();
    updatePrice();
    updateResultParams();
    updateCacheValues();

    element.classList.add('shown');
  };

  const onConstructorFormSubmit = (event) => {
    event.preventDefault();
    window.modal['constructor-callback'].show();
  };

  const onMainFormSubmit = async (event) => {
    event.preventDefault();

    const nameField = mainFormBlock.querySelector('input[name="NAME"]');
    const phoneField = mainFormBlock.querySelector('input[name="PHONE"]');
    const agreementField = mainFormBlock.querySelector('input[name="AGREEMENT"]');

    mainFormState.isValid = true;
    mainFormState.name = nameField.value;
    mainFormState.phone = phoneField.value;

    const nameContainer = nameField.closest('.input');
    const phoneContainer = phoneField.closest('.input');
    const agreementContainer = agreementField.closest('.checkbox');

    if (!mainFormState.name) {
      mainFormState.isValid = false;
      nameContainer.classList.add('is-error');
    }

    if (!phoneRegExp.test(mainFormState.phone)) {
      mainFormState.isValid = false;
      phoneContainer.classList.add('is-error');
    }

    if (!agreementField.checked) {
      mainFormState.isValid = false;
      agreementContainer.classList.add('is-error');
    }

    if (!mainFormState.isValid) {
      return;
    }

    const newResultParams = getActiveValues();
    const paramsHtml = createResultEmailTemplates(newResultParams);
    const price = `Цена: ${state.totalPrice} ${state.activeCurrency}`;
    const resultHtml = `${paramsHtml};${price}`;
    const formData = new FormData(mainFormBlock);
    formData.append('RESULT', resultHtml);

    await sendConstructorData(formData);

    window.modal['constructor-callback'].hide();
    window.modal.success.show();
  };

  const onResize = debounce(() => {
    updateCacheValues();
    updateParamsView();
    setFormCacheValues();
  }, RESIZE_HANDLER_DELAY);

  onInit();
  element.addEventListener('change', onCountryChange);
  element.addEventListener('change', onCpuChange);
  element.addEventListener('change', onAddressesChange);
  element.addEventListener('change', onNdsChange);
  element.addEventListener('scale-change', onScaleChange);
  element.addEventListener('select-change', onSelectChange);
  constructorFormBlock.addEventListener('submit', onConstructorFormSubmit);
  mainFormBlock.addEventListener('submit', onMainFormSubmit);
  window.addEventListener('resize', onResize);
};

const mountConstructor = () => {
  const element = document.querySelector('#constructor-server');
  const mainFormBlock = document.querySelector('#constructor-callback form');

  if (!element || !mainFormBlock) {
    return;
  }

  constructor(element, mainFormBlock);
};

document.addEventListener('DOMContentLoaded', mountConstructor);
