document.addEventListener('DOMContentLoaded', () => {
    if(document.querySelector('[data-constructor="component"]')){
        const component = 		document.querySelector('[data-constructor="component"]');
		const result = 		component.querySelector('[data-constructor="result"]');
		const count = 	component.querySelector('[data-constructor="count"]');
		const trigger =	component.querySelector('[data-constructor="trigger"]');
		const closeTrigger =	component.querySelector('[data-constructor="close"]');


        document.addEventListener('click', (e) => {
            const target = e.target 
            if (result.classList.contains('is-open') && !target.closest('[data-constructor="result"]')) {
                result.classList.remove('is-open')
                document.querySelector('body').classList.remove('_lock');
            }
        })

        trigger.onclick = (e) => {
            e.stopPropagation()
			result.classList.add('is-open')
			document.querySelector('body').classList.add('_lock');
        }

        closeTrigger.onclick = () => {
			result.classList.remove('is-open')
			document.querySelector('body').classList.remove('_lock');
        }
    }
})